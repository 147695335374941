import { d2lfetch } from 'd2l-fetch/src/index.js';
import { fetchDedupe } from 'd2l-fetch-dedupe';

export const headers = {
  'Content-Type': 'application/json',
};

export class PMClient {
  constructor() {
    d2lfetch.use({ name: 'dedupe', fn: fetchDedupe });
    const base = this.isDev() ? 'http://localhost:3000/' : '';
    this.apiUrl = `${base}api`;
  }

  get session() {
    return this._session;
  }

  set session(session) {
    this._session = session;
  }

  isDev() {
    return window.location.host === 'localhost:8080';
  }

  deployEnvironment(environmentId, branchName) {
    return this.request(`environments/${environmentId}/deploy`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ branchName }),
    }, 'json', `Started a deploy to ${environmentId} from the branch: ${branchName}`);
  }

  fetchEnvironment(id) {
    return this.request(`environments/${id}`);
  }

  fetchEnvironments() {
    return this.request('environments');
  }

  fetchDeployments(environmentId) {
    return this.request(`deployments/${environmentId}`);
  }

  fetchIDPMetadata(environmentId, tenantId, responseFormat = 'metadata-xml') {
    return this.request(`sso/metadata/${environmentId}/${tenantId}?responseFormat=${responseFormat}`, {}, 'text');
  }

  fetchTenantSSO(environmentId, tenantId) {
    return this.request(`tenants/${environmentId}/${tenantId}`);
  }

  fetchTenantSSOById(environmentId, domain) {
    return this.request(`tenants/${environmentId}/domain/${domain}`);
  }

  fetchLoginLink(environmentId, tenantId, userId, domain) {
    return this.request(`tenants/${environmentId}/${tenantId}/login/${userId}/${domain}`, {}, 'text');
  }

  fetchUsers() {
    return this.request('users');
  }

  fetchUser(id) {
    return this.request(`users/${id}`);
  }

  fetchSession() {
    const url = `session`; // Add a unique query parameter
    return this.request(url, {
      method: 'GET',
      headers: {
        ...headers,
        'Cache-Control': 'no-cache', // Instructs no-cache
      },
    }).then((session) => {
      this.session = session;
      return session;
    });
  }

  isProd(){
    return this.session.appName.startsWith('prod');
  }

  fetchEnvironmentHealth(environmentId) {
    return this.request(`environments/${environmentId}/health`);
  }

  fetchToken(environmentId, system) {
    return this.request(`environments/${environmentId}/token/${system}`);
  }

  set apiUrl(apiUrl) {
    this._apiUrl = apiUrl;
  }

  get apiUrl() {
    return this._apiUrl;
  }

  fetchPreferences() {
    return this.request('preferences');
  }

  fetchBranches() {
    return this.request('github/branches');
  }

  isValidDomain(environmentId, domain) {
    return this.request(`/environments/${environmentId}/valid_domain/${domain}`);
  }

  upsertUser(user) {
    return this.request('users', {
      method: 'PUT',
      headers,
      body: JSON.stringify(user),
    }, 'json', `${user.displayName || user.userId} saved successfully`);
  }

  removeUser(user) {
    return this.request(`users/${user.userId}`, {
      method: 'DELETE',
      headers,
    }, 'json', `${user.displayName || user.userId} removed successfully`);
  }

  removeFussUser(environmentId, tenantId, user) {
    return this.request(`users/${environmentId}/${tenantId}/${user.guid}`, {
      method: 'DELETE',
    });
  }

  updatePreferences(preferences) {
    return this.request('preferences', {
      method: 'PUT',
      headers,
      body: JSON.stringify(preferences),
    }, 'json', 'Preferences saved successfully');
  }

  updateTenantSSO(environmentId, tenantSSO) {
    return this.request(`tenants/${environmentId}/${tenantSSO.id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(tenantSSO),
    }, 'json', `${tenantSSO.displayName} saved successfully`);
  }

  removeEnvironment(environmentId) {
    return this.request(`environments/${environmentId}/remove`, {
      method: 'DELETE',
      headers,
    }, 'json', `successfully removed ${environmentId}`);
  }

  createEnvironment(environment) {
    return this.request('environments', {
      method: 'POST',
      headers,
      body: JSON.stringify(environment),
    }, 'json', `${environment.displayName} created successfully`);
  }

  fetchTenantUsers(environmentId, tenantId) {
    return this.request(`users/${environmentId}/${tenantId}`);
  }

  pushToFuss(environmentId, tenantId, user) {
    return this.request(`users/${environmentId}/${tenantId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(user),
    }, 'json', 'Successfully sent user to FUSS');
  }

  updateFussConfig(environmentId, fussConfig) {
    return this.request(`environments/${environmentId}/fuss`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(fussConfig),
    }, 'json', `${environmentId} fuss config updated successfully`);
  }

  testFussConnection(fussUrl) {
    return this.request(`test/fuss/${fussUrl}`);
  }

  createAlert(type, message) {
    const alertEvent = new CustomEvent('kraken-alert-event', {
      detail: { message, type },
      bubbles: true,
      composed: true });
    document.dispatchEvent(alertEvent);
  }

  async request(url, options = {}, responseType = 'json', message) {
    try {
      options.credentials = 'include';
      const response = await d2lfetch.fetch(`${this.apiUrl}/${url}`, options);

      if (response.ok && response.status === 200) {
        message && this.createAlert('success', message);
        return responseType === 'json' ? response.json() : response.text();
      } else if (response.status === 204) {
        return null;
      }
      throw response;
    } catch (e) {
      this.createAlert('error', e.message);
      throw e;
    }
  }
}
